/* eslint-disable no-undef */
import React, { useMemo } from 'react'
import SectionHero from '../../components/pishi/sectionHero/component'
import Header from '../../components/pishi/header/component'
import { Helmet } from 'react-helmet'
import { SectionProject } from '../../components/pishi/sectionProject/component'
import SectionStage from '../../components/pishi/sectionStage/component'
import { SectionHW } from '../../components/pishi/sectionHW/component'
import { SectionVivat } from '../../components/pishi/sectionVivat/component'
import { SectionForm } from '../../components/pishi/sectionForm/component'
import { SectionFAQ } from '../../components/pishi/sectionFaq/component'
import {
	Box,
	Flex,
	Link,
	Text,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Button,
} from '@chakra-ui/react'
import { InstaIcon } from '../../shared/icons/InstaIcon'
import { Link as LinkS } from 'react-scroll'
import useWindowSize from '../../hooks/useWindowSize'

const Pishi = () => {
	const { width } = useWindowSize()
	const { isOpen, onClose, onOpen } = useDisclosure()

	const isMobile = useMemo(() => width < 768, [width])

	return (
		<Box background="#F3F3F4">
			<Helmet>
				{/* <meta property="og:image:type" content="image/png" />
				<meta property="og:image:width" content="1440" />
				<meta property="og:image:height" content="720" />
				<meta
					name="image"
					property="image"
					content="https://holywater.tech/images/pishi/desc/hero1.png"
				/>
				<meta
					property="description"
					content='📚 Надсилай свій романтичний текст та отримай шанс на контракт з HOLYWATER і Vivat! Проєкт "Пиши": Прийом робіт стартує 5 серпня. Поспішай! ✨'
				/>
				<meta
					property="title"
					content="Як Стати Письменником - Де Можна Опублікувати Свою Книгу | Пиши з HOLYWATER"
				/> */}
				<meta name="robots" content="noindex, nofollow" />
				<meta
					property="og:image"
					content="https://holywater.tech/images/pishi/desc/hero1.png"
				/>
				<meta
					property="og:description"
					content='📚 Надсилай свій романтичний текст та отримай шанс на контракт з HOLYWATER і Vivat! Проєкт "Пиши": Прийом робіт стартує 5 серпня. Поспішай! ✨'
				/>
				<meta
					property="og:title"
					content="Як Стати Письменником - Де Можна Опублікувати Свою Книгу | Пиши з HOLYWATER"
				/>
				<meta
					property="twitter:title"
					content="Як Стати Письменником - Де Можна Опублікувати Свою Книгу | Пиши з HOLYWATER"
				/>
				<link rel="canonical" href="https://holywater.tech/pyshy" />
				<title>
					Як Стати Письменником - Де Можна Опублікувати Свою Книгу | Пиши з
					HOLYWATER
				</title>
			</Helmet>
			<Header />
			<main>
				<SectionHero />
				<SectionProject />
				<SectionStage />
				<SectionHW />
				<SectionVivat />
				<SectionForm onOpen={onOpen} />
				<SectionFAQ />
			</main>
			<footer
				style={{
					padding: isMobile ? '40px 15px' : '20px 100px',
					background: '#03060D',
				}}
			>
				{isMobile ? (
					<>
						<Flex mb="42px" justifyContent="space-between" alignItems="center">
							<Box>
								<Link textDecoration="underline" mb="10px" onClick={onOpen}>
									Правила та умови
								</Link>
								<Text variant="pishiRegular">With love from 🇺🇦 Ukraine</Text>
							</Box>
							<InstaIcon w="20px" h="20px" />
						</Flex>
						<Box
							borderRadius="10px"
							border="1px solid #FFF"
							background="#03060D"
							p="12px 0"
							textAlign="center"
						>
							<Link
								display={{ md: 'block' }}
								as={LinkS}
								to="form"
								spy={true}
								smooth={true}
								duration={1000}
								fontSize="18px"
								color="#fff"
								width="100%"
								fontSie="18px"
								fontFamily="Inter-Medium"
								fontweight="500"
								background="transparent"
								// onClick={() => {
								// 	onClose()
								// 	handleLinkClick('about')
								// }}
							>
								Надіслати свій текст
							</Link>
						</Box>
					</>
				) : (
					<Flex justifyContent="space-between" alignItems="center">
						<Flex justifyContent="space-between" alignItems="center">
							<Box>
								<Link
									textDecoration="underline"
									mb="10px"
									onClick={onOpen}
									color="#fff"
								>
									Правила та умови
								</Link>
								<Text variant="pishiRegular" mt="10px">
									With love from 🇺🇦 Ukraine
								</Text>
							</Box>
						</Flex>
						<Flex alignItems="center">
							<InstaIcon
								w="24px"
								h="24px"
								mr="56px"
								cursor="pointer"
								onClick={() =>
									window.open('https://www.instagram.com/pyshy.ua/', '_blank')
								}
							/>
							<Box
								borderRadius="10px"
								border="1px solid #FFF"
								background="#03060D"
								p="12px 32px"
								textAlign="center"
								height="50px"
							>
								<Link
									display={{ md: 'block' }}
									as={LinkS}
									height="50px"
									to="form"
									spy={true}
									smooth={true}
									duration={1000}
									fontSize="18px"
									color="#fff"
									width="100%"
									fontSie="18px"
									background="transparent"
									cursor="pointer"
									// onClick={() => {
									// 	onClose()
									// 	handleLinkClick('about')
									// }}
								>
									Надіслати свій текст
								</Link>
							</Box>
						</Flex>
					</Flex>
				)}
				<Modal isOpen={isOpen} onClose={onClose}>
					<ModalOverlay />
					<ModalContent
						// minW={348}
						w="348px"
						minW="70vw"
						minH="70vh"
						maxH="80vh"
						p="10px"
						m="auto"
						overflowY="auto"
						borderRadius="8px"
					>
						<ModalHeader>
							РЕГЛАМЕНТ ПРОВЕДЕННЯ ВІДКРИТОГО ЛІТЕРАТУРНОГО КОНКУРСУ ДЛЯ
							УКРАЇНСЬКИХ АВТОРІВ “ПИШИ”
						</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<>
								1. ЗАГАЛЬНІ ПОЛОЖЕННЯ 1.1. Організатором конкурсу є ХВ Фентезі
								Лімітед [HW Fantasy Limited] (надалі «Організатор») у
								партнерстві з ТОВ &quot;Видавництво Віват&quot; (надалі
								«Партнер»). 1.2. Цей Регламент проведення відкритого
								літературного конкурсу для українських авторів (надалі
								«Регламент») визначає умови та порядок проведення конкурсу на
								створення творів у визначених Організатором жанрах. 1.3. Конкурс
								проводиться на території України, підконтрольній українській
								владі, з 12:00:00 год. 05.08.2024 по 23:59:59 год. 30.09.2024
								включно за Київським часом. 1.4. Оголошення результатів Конкурсу
								відбудеться 21.10.2024 після визначення Творів-переможців
								відповідно до цього Регламенту. 1.5. Цей Регламент, зміни та/або
								доповнення та/або роз’яснення до нього, уся необхідна інформація
								щодо заходів, етапів проведення Відбору, дати оголошення за
								результатами першого та другого етапів Відбору, реєстраційна
								форма тощо розміщується на конкурсній платформі за посиланням:
								https://holywater.tech/pyshy. 1.6. РЕЄСТРУЮЧИСЬ ДЛЯ УЧАСТІ В
								КОНКУРСІ КОНКУРСАНТ БЕЗОПЛАТНО НАДАЄ ОРГАНІЗАТОРУ ВИКЛЮЧНУ,
								ОБМЕЖЕНУ ЛІЦЕНЗІЮ НА ВИКОРИСТАННЯ, ЦИФРОВУ ПУБЛІКАЦІЮ,
								АДАПТАЦІЮ, В ТОМУ ЧИСЛІ ЦИФРОВУ, ТА ПУБЛІЧНЕ ВИКОНАННЯ ТВОРУ, З
								МЕТОЮ ОЦІНКИ ТВОРУ СЕРЕД КОРИСТУВАЧІВ ПЛАТФОРМ ОРГАНІЗАТОРА ТА
								ВИЗНАЧЕННЯ ЇХ ПОТЕНЦІЙНОЇ ПОПУЛЯРНОСТІ СЕРЕД ЧИТАЧІВ НА ВЕСЬ
								СТРОК ПРОВЕДЕННЯ КОНКУРСУ. 2. ВИЗНАЧЕННЯ ТЕРМІНІВ 2.1. Відбір −
								процес оцінювання Творів Організатором Конкурсу на відповідність
								встановленим формальним вимогам, а також критеріям, визначеним
								Організатором на власний розсуд. 2.2. Конкурс − це змагання
								серед українських авторів, яке проводиться з метою підтримки та
								популяризації літературних творів у жанрах Романтика,
								Паранормальна Романтика, Романтичний Трилер, Фентезійна
								Романтика, Альфа-романтика та сюжетах Альфа-самець, Роман з
								мільярдером, Таємний мільярдер, Романтика з перевертнями,
								Романтика з мафією, Від ненависті до кохання, Смілива героїня,
								Примусове одруження за допомогою бізнес-можливостей Організатора
								та його Партнера. Конкурс передбачає подання учасниками власних
								літературних творів для оцінювання Організатором, з подальшим
								визначенням Творів-переможців. 2.3. Реєстраційна форма – це
								електронна форма, розміщена на платформі Організатора, яку
								заповнює особа, яка бажає брати участь в Конкурсі, включаючи
								необхідну інформацію про себе та Твір. Реєстраційна форма
								доступна за посиланням: https://holywater.tech/pyshy. 2.4.
								Платформи організатора – це електронні ресурси Організатора, на
								яких буде розміщено Твори з метою проведення 2 (другого) етапу
								Відбору, включаючи, але не обмежуючись: Застосунок My Passion:
								Romance Books та My Passion: Reading App для iOS та Android
								відповідно
								(https://apps.apple.com/us/app/my-passion-romance-books/id1568149521;
								https://play.google.com/store/apps/details?id=gm.passion.app&hl=en).
								2.5. Переможець – це автор Твору, який в процесі відбору
								Організатор визначає як найкращий за сукупністю критеріїв,
								визначених Організатором. Переможець може бути один або
								декілька, на розсуд Організатора. Серед переможців Організатор
								визначить призерів - авторів Творів, які посіли перші три місця
								в рейтингу Конкурсу. 2.6. Конкурсна платформа – це створена
								Організатором інтернет-платформа для проведення Конкурсу на якій
								розміщена вся інформація про Конкурс, включно з цим Регламентом,
								Реєстраційна форма, результати Відбору та інформація про всі
								можливі зміни в строках та умовах проведення Конкурсу. Конкурсна
								платформа доступна за посиланням: https://holywater.tech/pyshy.
								2.7. Електронна пошта – це контактна електронна пошта
								Конкурсанта, вказана ним в Реєстраційній формі, через яку
								здійснюється комунікація між Організатором / його Партнером та
								Конкурсантом. 2.8. Твір − це оригінальний літературний текст,
								створений інтелектуальною, творчою працею Конкурсанта, який
								Конкурсант подає для участі у Конкурсі. 2.9. Твори-переможці −
								це Твори, які в процесі відбору здобувають перемогу за
								сукупністю художніх, творчих, технічних та інших критеріїв
								оцінювання. Технічні критерії оцінювання, включно, але не
								обмежуючись, результати метрик читацького інтересу є
								переважаючими критеріями оцінювання. 3. ВИМОГИ ДО УЧАСНИКІВ
								КОНКУРСУ 3.1. До участі у Конкурсі запрошуються громадяни
								України, що є повнолітніми згідно з законодавством України
								станом на дату початку Конкурсу, за винятком осіб, які мають або
								мали публічну антиукраїнську позицію чи підтримували збройну
								агресію російської федерації проти України, а також осіб, котрі
								перебувають на тимчасово окупованих територіях України, або
								проживають на території російської федерації або Республіки
								Білорусь. 3.2. До участі у Конкурсі не допускаються особи, які
								не відповідають вимогам викладеним у п. 3.1. цього Регламенту,
								Реєстраційні форми яких містять недостовірну, неповну,
								неправдиву інформацію, а також які містять інформацію
								образливого та/або неетичного характеру, що порушують права та
								законні інтереси будь-яких осіб. До участі в Конкурсі також не
								допускаються працівники Організатора та його Партнера. 3.3.
								Особа, яка: 3.3.1 відповідає вимогам п. 3.1. цього Регламенту та
								3.3.2 не порушує вимоги п. 3.2 цього Регламенту, та 3.3.3
								погоджується з умовами та вимогами цього Регламенту, та 3.3.4
								заповнила Реєстраційну форму у строки, визначений п. 4.1 цього
								Регламенту, допускається до участі у Конкурсі (надалі
								«Конкурсант») про що буде повідомлена на Електронну пошту,
								вказану в Реєстраційній формі. 4. СТРОК ЗАПОВНЕННЯ РЕЄСТРАЦІЙНОЇ
								ФОРМИ 4.1. Реєстраційна форма доступна для заповнення з 12:00:00
								год. 05.08.2024 по 23:59:59 год 30.09.2024. 4.2. Особи, що
								заповнили Реєстраційну форму після спливу строку реєстрації
								передбаченого п.4.1. цього Регламенту не допускаються до участі
								в Конкурсі. 5. ВИМОГИ ДО ТВОРІВ ТА ЇХ УЧАСТІ У КОНКУРСІ 5.1. До
								участі в Конкурсі допускаються Твори, які: : 5.1.1 відповідають
								жанровій приналежності, визначеній на Конкурсній платформі, а
								саме: Романтика, Паранормальна Романтика, Романтичний Трилер,
								Фентезійна Романтика, Альфа-романтика та сюжетам Альфа-самець,
								Роман з мільярдером, Таємний мільярдер, Романтика з
								перевертнями, Романтика з мафією, Від ненависті до кохання,
								Смілива героїня, Примусове одруження; ; 5.1.2 об&apos;єм Твору –
								від 280 000 знаків із пробілами; 5.1.3 подані у строк,
								встановлений у п. 4.1 цього Регламенту; 5.1.4 щодо яких відсутні
								будь-які правові обмеження на публікацію твору, в тому числі,
								але не виключно: (a) Твір надається в рамках Конкурсу на
								ексклюзивних засадах і раніше не був опублікований у будь-якому
								форматі (включаючи, але не обмежуючись, друкованому,
								електронному, онлайн тощо); (b) Твір є результатом власної
								інтелектуальної праці Конкурсанта та не порушує жодних
								авторських прав, прав інтелектуальної власності чи інших
								законних прав третіх осіб; (c) на Твір не розповсюджуються жодні
								права третіх осіб, які могли б обмежити його публікацію та
								використання Організатором та/або його Партнером у рамках
								Конкурсу та в подальшому. 5.2. Під час участі у Конкурсі
								Конкурсант зобов’язаний: 5.2.1 уважно ознайомитись із вимогами
								цього Регламенту; 5.2.2 виконувати умови та вимоги цього
								Регламенту у повному обсязі; 5.2.3 добросовісно співпрацювати та
								комунікувати із Організатором, в тому числі, на запит
								Організатора, розкривати інформацію щодо деталей створення Твору
								та ідей, які в нього закладались; 5.2.4 негайно повідомити
								Організатора про будь-яке наявне чи минуле комерційне
								використання Твору чи його частини. 5.3. Цим Конкурсант
								гарантує, що: 5.3.1 Твір надається в рамках Конкурсу на
								ексклюзивних засадах; ; 5.3.2 Твір є результатом його власної
								інтелектуальної праці та не порушує жодних авторських прав, прав
								інтелектуальної власності чи інших законних прав третіх осіб;
								5.3.3 на Твір не розповсюджуються жодні права третіх осіб, які
								могли б обмежити його публікацію та використання Організатором
								та/або його Партнером у рамках Конкурсу та в подальшому. 5.4.
								Конкурсант, який умисно або з необережності надав неправдиві
								запевнення передбачені у п. 5.3. цього Регламенту зобов’язаний
								відшкодувати стороні, яка покладалась на такі запевнення,
								будь-які прямі та непрямі збитки, завдані у зв’язку з
								неправдивістю таких запевнень. 5.5. Реєструючись для участі в
								Конкурсі, Конкурсант погоджується із тим, що Організатор залишає
								за собою право не повідомляти Конкурсантам критерії відбору
								Творів-переможців, а також результати метрик читацького інтересу
								та інших статистичних даних, отриманих в результаті проведення
								відбору. 6. ПОРЯДОК ПРОВЕДЕННЯ ВІДБОРУ 6.1. Строк проведення
								відбору з 12:00:00 год. 06.08.2024 року по 23:59:59 год.
								18.10.2024 року; 6.2. Організатор відбирає Твори за сукупністю
								художніх, творчих та інших критеріїв, визначених Організатором
								на власний розсуд. Організатор адаптує та локалізує Твір під
								Платформи організатора. Цей етап включає проведення оцінки
								творів серед користувачів таких платформ, шляхом надання Творів
								для прочитання та збору аналітичних даних з метою визначення їх
								потенційної популярності серед читачів. 6.3. На основі зібраної
								аналітики Організатор 21 жовтня 2024 року оголошує
								авторів-переможців Конкурсу та Твори-переможці шляхом публікації
								їх переліку на Конкурсній платформі та у соціальних мережах
								Організатора і Партнера, а також надсилання листа на Електронну
								пошту Конкурсанта.. 6.4. Організатор має право не відбирати
								жоден Твір та не оголошувати Твори-переможці, не оголошувати
								Переможця та не публікувати жоден Твір на Платформах
								організатора. 6.5. Організатор має право у будь-який момент, як
								під час Конкурсу так і після його завершення, на власний розсуд
								припинити співпрацю з будь-яким автором, який подав Твір на
								Конкурс, незалежно від того, чи був автор оголошений Переможцем.
								6.6. Організатор не несе відповідальності за витрати, понесені
								Конкурсантом у зв’язку з підготовкою до Конкурсу, не надає жодні
								технічні та матеріальні ресурси для підготовки до Конкурсу. 6.7.
								Призерам Конкурсу (авторам, чиї твори посіли перші три місця в
								рейтингу Конкурсу), Організатор виплатить призові у розмірі 30
								000 (тридцять тисяч) гривень у порядку та на умовах, визначених
								у договорі між Організатором та відповідним Переможцем. 6.8.
								Після оголошення Переможців Конкурсу, Організатор укладає
								договори із Переможцями Конкурсу щодо публікації
								Творів-переможців на Платформах організатора. Умови таких
								договорів узгоджуються між Організатором та відповідним автором.
								6.9. Партнер має право на власний розсуд укласти з Переможцями,
								договір про видання паперової книги на умовах, що погоджені між
								Партнером та автором. Організатор не гарантує Переможцям
								укладення договору із Партнером та не несе жодної
								відповідальності за дії або бездіяльність Партнера під час
								проведення Конкурсу та в будь-який момент після нього. 7. ПРАВА
								ІНТЕЛЕКТУАЛЬНОЇ ВЛАСНОСТІ 7.1. Після оголошення результатів
								Конкурсу, між Організатором та Переможцями укладається договори
								про співпрацю та розподіл майнових права інтелектуальної
								власності на Твори-переможці. 7.2. Обов’язковою умовою подальшої
								співпраці Організатора з Переможцем є укладення договору про
								розподіл майнових прав інтелектуальної власності на
								Твір-переможець, за яким Переможець передає права володіти,
								користуватися та розпоряджатися Творами-переможцями в цифровому
								форматі (надалі – «Цифрові права»), включно, але не обмежуючись:
								7.2.1 Ексклюзивні права публікувати Твори-переможці на
								Платформах організатора, в тому числі вносити зміни до
								Творів-переможців, які є необхідними для публікації
								Творів-переможців на Платформах організатора; 7.2.2 Ексклюзивні
								права на публікацію та розповсюдження Творів в електронному
								вигляді (електронні книжки, аудіокниги тощо); 7.2.3 Ексклюзивні
								права на екранізацію Творів-переможців (кіно, серіали, анімація
								та інші аудіовізуальні твори); 7.2.4 Ексклюзивні права на
								адаптацію Творів-переможців для використання в
								віртуальній/доповненій реальності, створення відеоігор тощо.
								7.3. Обов’язковою умовою подальшої співпраці Партнера з
								Переможцем є укладення договорів про співпрацю та розподіл
								майнових прав інтелектуальної, за яким Партнер отримує виключні
								права на публікацію та розповсюдження Творів-переможців у
								друкованому (паперовому) форматі українською мовою на території
								України (надалі – «Друковані права»). 7.4. Без шкоди для п 7.2
								та 7.3 цього Регламенту, усі умови договорів будуть окремо
								погодженні із кожним Переможцем та підлягатимуть обговоренню та
								погодженню після завершення Конкурсу. 8. ПРИКІНЦЕВІ ПОЛОЖЕННЯ
								8.1. Зворотний зв’язок учасники отримують з офіційної
								електронної адреси pyshy@holywater.tech. 8.2. Організатор
								Конкурсу має право відмовитися від його проведення, якщо
								проведення конкурсу стало неможливим за обставин, які від нього
								не залежать.
							</>
						</ModalBody>

						<ModalFooter>
							<Button colorScheme="blue" mr={3} onClick={onClose}>
								Закрити
							</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
			</footer>
		</Box>
	)
}

export default Pishi
